import React from 'react'
import Container from '@material-ui/core/Container'
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import Checkbox from "@material-ui/core/Checkbox"
import Box from "@material-ui/core/Box"
import { makeStyles } from '@material-ui/styles'


let ItemStyle = makeStyles({
	listBackground: {
		backgroundColor: "#F2F7FD",
		borderRadius: "15px",
	},
	bold: {
		fontWeight: "bold",
		fontFamily: "visby",
	},
})


const Item = ({ document = "document", status, openDialog = () => {} }) => {
	let styles = ItemStyle()
	return (
		<Container onClick={!status ? openDialog : () => { }}>
			<List className={styles.listBackground}>
				<ListItem>
					<Checkbox checked={status} />
					<ListItemText
						primary={
							<Typography
								component={"div"}
								variant="subtitle1"
								style={{ lineHeight: "normal", fontWeight: "bold" }}
							>
								{document}
							</Typography>
						}
					></ListItemText>
					<Box>
						<Typography variant="caption">
							{status ? "" : "Click here to submit document"}
						</Typography>
					</Box>
				</ListItem>
			</List>
			<Box mb={2} />
		</Container>
	)
}


export default Item