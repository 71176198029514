import React, { useState, useEffect } from 'react'


import Button from "@material-ui/core/Button"

import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"

import IconButton from "@material-ui/core/Icon"
import CancelIcon from "@material-ui/icons/Cancel"
import { LinearProgress } from '@material-ui/core'

import { uploadRiderDocuments } from '../../../api/spark-express/auth'



const UploadDialog = ({ title = 'test', isOpen = false, closeDialog = () => { }, onUpload = () => { }, documentsNeeded = ['Harap ng lisensiya'], documentType = 'sample document' }) => {
	const [uploadProgress, updateUploadProgress] = useState({
		status: false,
		value: 0
	})

	const [documentRequirement, updateDocumentRequirements] = useState([])
	const [readyToUpload, toggleReadyToUpload] = useState(false)


	useEffect(() => {
		let mounted = true

		console.log(documentsNeeded)
		if (mounted)
			updateDocumentRequirements(documentsNeeded)

		return () => {
			mounted = false
		}
	}, [])


	useEffect(() => {

		let mounted = true

		if (mounted) {
			let uploadedNumberOfDocuments = documentRequirement.filter((document) => typeof document?.name === 'string').length
			console.log(uploadedNumberOfDocuments === documentRequirement.length)
			if (uploadedNumberOfDocuments === documentRequirement.length)
				toggleReadyToUpload(true)
			else
				toggleReadyToUpload(false)
		}

		return () => {
			mounted = false
		}

	}, [documentRequirement])

	function uploadFile() {
		let data = new FormData()
		data.append('doc_type', documentType)
		documentRequirement.forEach(document => {
			data.append('documents', document)
		})

		uploadRiderDocuments(data, (progress) => {
			updateUploadProgress({
				status: true,
				value: Math.round((progress.loaded / progress.total) * 100)
			})
		}).then(response => {
			if (response.status === 200) {
				onUpload(response.data)
				closeDialog()
			}
			updateDocumentRequirements(documentsNeeded)
			updateUploadProgress({ status: false, value: 0 })
		})
	}

	function pickFile(event, index) {
		let data = [...documentRequirement.slice(0, index), event.target.files[0], ...documentRequirement.slice(index + 1)]
		updateDocumentRequirements(data)
	}

	function removeFile(index) {
		let data = [...documentRequirement.slice(0, index), documentsNeeded[index], ...documentRequirement.slice(index + 1)]
		console.log(data)
		updateDocumentRequirements(data)
	}

	return <Dialog open={isOpen}>
		<DialogTitle>{title}</DialogTitle>
		<DialogContent>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				{documentRequirement.map((document, index) => <>{document.name ? <div style={{ display: "flex", justifyContent: "space-between", marginBottom: '0.5em' }}>
					<div>{document.name}</div>
					<IconButton>
						<CancelIcon onClick={() => removeFile(index)} />
					</IconButton>
				</div> : <Button variant="contained" component="label" style={{ marginpBottom: '0.5em' }}>
					{document}
					<input type="file" hidden onChange={(event) => pickFile(event, index)} />
				</Button>}</>)}
			</div>
		</DialogContent>

		{uploadProgress.status && <LinearProgress variant="determinate" value={uploadProgress.value} style={{ width: '90%', marginTop: "0.5em", marginLeft: "auto", marginRight: "auto" }} />}
		<DialogActions>
			{!readyToUpload}
			<Button autoFocus color="primary" onClick={uploadFile} disabled={!readyToUpload}>
				Upload
			</Button>
			<Button autoFocus onClick={closeDialog} color="primary">
				CLose
			</Button>
		</DialogActions>
	</Dialog>
}


export default UploadDialog