import React, { useState } from 'react'
import Item from './item'
import Dialog from './dialog'

const UploadItem = ({ status = false, document = 'valid id', title = 'Sample Title', documentsNeeded = ['harap ng lisensiya'], onUpload = () => {} }) => {
	let [dialogOpen, toggleDialog] = useState(false)
	return <>
		<Item document= {title} status={status} openDialog={() => toggleDialog(true)} />
		<Dialog title = {title} documentType = {document} isOpen={dialogOpen} documentsNeeded={documentsNeeded} closeDialog={() => toggleDialog(false)} onUpload = {onUpload}/>
	</>
}


export default UploadItem
