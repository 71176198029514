import React, { useEffect, useState } from 'react'
import { Container, Button, Typography } from '@material-ui/core'
import UploadItem from '../components/spark-express/uploadItem'


import { getUser, logOut, updateDocuments } from '../services/auth'
import { navigate } from 'gatsby'

const Documents = () => {
  let [submittedDocuments, updateSubmittedDocuments] = useState({})

  useEffect(() => {
    let mounted = true

    if (mounted) {
      console.log(getUser())
      initializeSubmittedDocuments()
    }

    return () => {
      mounted = false
    }
  }, [])


  useEffect(() => {
    let mounted = true

    if (mounted) {
      let status = checkIfDocumentsAreSubmitted(getUser().transportationType, submittedDocuments)
      if(status) {
        navigate('/documents-complete')
      }
    }

    return () => {
      mounted = false
    }
  }, [submittedDocuments])

  function initializeSubmittedDocuments() {
    let b = {
      ...submittedDocuments
    }

    getUser().documents.forEach((document) => {
      b[`${document.category}`] = true
    })

    updateSubmittedDocuments(b)
  }


  function checkIfDocumentsAreSubmitted(transportationType = 'bike', submittedDocuments = {}) {
    console.log('transpo is ')
    console.log(transportationType)
    console.log(submittedDocuments)
    if (transportationType === 'bike' || transportationType === 'walk') {
      return submittedDocuments?.['nbi-clearance'] && submittedDocuments?.['baranggay-certificate'] && submittedDocuments?.['1x1-picture']
    } else {
      return submittedDocuments?.['drivers-license'] && submittedDocuments?.['vehicle-orcr'] && submittedDocuments?.['nbi-clearance'] && submittedDocuments?.['baranggay-clearance'] && submittedDocuments?.['1x1-picture']
    }
  }


  function onUpload(data) {
    console.log(data)
    updateDocuments(data.documents)
    initializeSubmittedDocuments()
  }


  return <Container max-width="sm" style = {{
    paddingTop: '2em'
  }}>
    <Typography variant = 'h5'>Submit documents to be verified as Spark Express Rider</Typography>
    {(getUser().transportationType === 'motorcycle' || getUser().transportationType === 'sedan') && <UploadItem onUpload={(data) => onUpload(data)} status={submittedDocuments['drivers-license']} title="Driver's License" document='drivers-license' documentsNeeded={['Harap ng Lisensiya', 'Likod ng Lisensiya']} />}
    {(getUser().transportationType === 'motorcycle' || getUser().transportationType === 'sedan') && <UploadItem onUpload={(data) => onUpload(data)} status={submittedDocuments['vehicle-orcr']} title='Vehicle OR/CR' document='vehicle-orcr' documentsNeeded={['Updated ORCR']} />}
    <UploadItem onUpload={(data) => onUpload(data)} status={submittedDocuments['nbi-clearance']} title='NBI Clearance' document='nbi-clearance' documentsNeeded={['Barrangay Clearance']} />
    <UploadItem onUpload={(data) => onUpload(data)} status={submittedDocuments['baranggay-certificate']} title='Baranggay Certificate' document='baranggay-certificate' documentsNeeded={['Barrangay Clearance']} />
    <UploadItem onUpload={(data) => onUpload(data)} status={submittedDocuments['1x1-picture']} title='1X1 Picture' document='1x1-picture' documentsNeeded={['1x1 Picture']} />

    <Button fullWidth onClick={logOut} style={{ backgroundColor: "#FFCF10" }}>
      LogOut
    </Button>
  </Container>
}

export default Documents